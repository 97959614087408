exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-geschichte-js": () => import("./../../../src/pages/geschichte.js" /* webpackChunkName: "component---src-pages-geschichte-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-multimedia-js": () => import("./../../../src/pages/multimedia.js" /* webpackChunkName: "component---src-pages-multimedia-js" */),
  "component---src-pages-neuwagen-js": () => import("./../../../src/pages/neuwagen.js" /* webpackChunkName: "component---src-pages-neuwagen-js" */),
  "component---src-pages-ocassionen-js": () => import("./../../../src/pages/ocassionen.js" /* webpackChunkName: "component---src-pages-ocassionen-js" */),
  "component---src-pages-stellen-js": () => import("./../../../src/pages/stellen.js" /* webpackChunkName: "component---src-pages-stellen-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

